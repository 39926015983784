@import 'color/colors';
@import 'mixins';

// $numeric-md-font-size: 28pt;
// $mobile-header-font-size: 21pt;
.solutionsTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1280px;
  max-width: 96vw;
  margin-bottom: 40px;
  &__topSection {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
    &--paddingBottom {
      margin-bottom: 100px;
    }
  }
  &__topSectionLeft {
    display: flex;
    flex-direction: column;
    margin-top: 46px;
  }
  &__topSectionSubheader {
    @include text__subheader;
    margin-right: 80px;
  }
  &__topSectionAction {
    min-width: 192px;
    height: 50px;
    margin-top: 40px;
  }
  &__topSectionImageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__topSectionImage {
    width: 600px;
  }
  &__roundedList {
    position: relative;
    top: -300px;
    left: -210px;
    &--hide {
      display: none;
    }
  }
  &__section {
    &--pricing {
      display: grid;
      grid-template-columns: repeat(3, 386px);
      justify-content: center;
      gap: 16px;
      margin-bottom: 40px;
      align-self: flex-start;
    }
  }
  &__sectionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--even {
      flex-direction: row-reverse;
    }
  }
  &__sectionRowNumeralWrapper {
    margin: 0 30px;
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
  }
  &__sectionRowVerticalRule {
    width: 1px;
    background-color: $border;
    flex: 1;
    &--hidden {
      background-color: transparent;
    }
  }
  &__sectionRowNumeral {
    font-size: 42px;
    line-height: 50px;
    font-weight: 100;
    color: $light-110;
    margin: 8px 0;
  }
  &__sectionItem {
    display: flex;
    width: 44%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &--leftPadding {
      padding-left: 48px;
    }
    &--wide {
      width: 48%;
    }
  }
  &__sectionItemRow {
    display: flex;
    h2 {
      @include text__h2;
    }
    p {
      @include text__p;
    }
  }
  &__sectionItemImage {
    min-width: 500px;
    max-width: 520px;
  }
  &__sectionItemIcon {
    width: 40px;
    margin-right: 16px;
    color: $text__normal;
  }
  &__sectionItemList {
    list-style: none;
    padding-left: 20px;
    li {
      @include text__p;
      font-size: 16px;
      line-height: 26px;
      margin: 0 0 2px;
      &::before {
        content: '•';
        color: $text--dark-1;
        margin-right: 12px;
      }
    }
  }
  &__horizontalRule {
    width: 600px;
    max-width: 80vw;
    height: 1px;
    background-color: $border;
    margin: 40px 0 60px;
  }
  &__getStarted {
    grid-column: 1 / 4;
    p {
      @include text__p2;
      margin-bottom: -20px;
    }
  }
  &__pricingCard {
    height: 580px;
    border: 10px solid $light-blue-2;
    background-color: $white;
    border-radius: 24px;
    padding: 40px 40px 30px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--even {
      background-color: $light-blue-2;
    }
  }
  &__pricingCardList {
    flex: 1;
    h4 {
      @include text__bold;
    }
    ul {
      list-style: none;
      padding-left: 20px;
    }
    li {
      @include text__p;
      font-size: 16px;
      line-height: 26px;
      &::before {
        content: '•';
        color: $text--dark-1;
      }
    }
  }
  // &__pricingCardBottom {
  // }
  &__pricingCardPrePrice {
    @include text__p2;
  }
  &__pricingCardPrice {
    display: flex;
    align-items: flex-end;
    h2 {
      color: #3ba8ed;
      font-size: 34px;
      font-weight: 500;
      margin: 0 12px 0 0;
    }
    p {
      @include text__p2;
    }
  }
  &__pricingCardPostPrice {
    margin-left: 12px;
    color: $text--dark-1;
    font-weight: 500;
    font-size: 16px;
  }
  &__pricingCardAction {
    margin: 12px 0;
    align-self: flex-start;
    .myBtn--md {
      min-width: 188px;
    }
  }
  &__pricingCardFooter {
    height: 60px;
    h4 {
      font-size: 12px;
      font-weight: 400;
      color: $text--dark-1;
      line-height: 18px;
      margin: 0;
    }
  }

  @media (max-width: 1400px) {
    max-width: 1140px;
    width: 95%;
    &__topSectionSubheader {
      margin-right: 20px;
    }
  }
  @media (max-width: 1240px) {
    max-width: 1080px;
    width: 100vw;
    &__topSectionLeft {
      margin-left: 40px;
    }
    &__roundedList {
      top: -320px;
      left: -160px;
    }
    &__section--wide {
      margin: 0 40px;
    }
    &__section--pricing {
      grid-template-columns: repeat(3, 320px);
      align-self: auto;
    }
    &__pricingCard {
      padding: 30px;
      height: 520px;
      &--tall {
        height: 608px;
      }
    }
    &__pricingCardPrice {
      h2 {
        font-size: 28px;
      }
    }
  }
  @media (max-width: 1080px) {
    &__roundedList {
      top: -320px;
      left: -40px;
    }
    &__sectionRowNumeralWrapper {
      margin: 0 12px;
    }
    &__sectionItemImage {
      max-width: 42vw;
      margin-left: -20px;
      min-width: 480px;
    }
    &__section--pricing {
      grid-template-columns: repeat(3, 300px);
      gap: 4px;
    }
    &__getStarted {
      margin-left: 40px;
    }
    &__pricingCard {
      padding: 20px;
      height: 500px;
      &--tall {
        height: 580px;
      }
    }
    &__pricingCardList {
      li {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 960px) {
    &__roundedList {
      left: -20px;
    }
  }
  @media (max-width: 876px) {
    &__topSectionImage {
      width: 520px;
    }
    &__roundedList {
      top: -320px;
      left: 0px;
      .RoundedList__wrapper {
        width: 340px;
      }
      li {
        font-size: 24px;
        img {
          width: 32px;
        }
      }
    }
    &__sectionItem--image {
      align-self: center;
    }
    &__section--pricing {
      grid-template-columns: repeat(2, 300px);
      gap: 20px;
    }
    &__getStarted {
      grid-column: 1 / 3;
      margin-left: 0;
    }
    &__sectionRow {
      flex-direction: column;
      align-items: flex-start;
    }
    &__sectionRowNumeralWrapper {
      display: none;
    }
    &__sectionItemImage {
      max-width: 90vw;
    }
    &__sectionItem {
      width: 90%;
      &--leftPadding {
        padding-top: 20px;
        border-top: thin solid $border;
      }
    }
  }
  @media (max-width: 768px) {
    &__topSection {
      flex-direction: column;
    }
    &__topSectionSubWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__topSectionAction {
      margin-top: 0;
      margin-right: 40px;
    }
    &__topSectionImage {
      width: 90%;
    }
    &__roundedList {
      width: 100%;
      top: -280px;
      left: 40px;
      .RoundedList__wrapper {
        display: block;
        width: 320px;
        li {
          font-size: 20px;
          img {
            width: 28px;
          }
        }
      }
    }
  }
  @media (max-width: 646px) {
    &__section--pricing {
      grid-template-columns: repeat(1, 320px);
    }
    &__getStarted {
      grid-column: 1 / 2;
      margin-left: 20px;
    }
  }
}
