@import 'color/colors';
@import 'mixins';

.SolutionCard {
  &__wrapper {
    padding: 16px;
    width: 340px;
    height: 160px;
    display: flex;
    justify-content: first baseline;

    &--invisible {
      opacity: 0;
      @media (max-width: 976px) {
        display: none;
      }
    }

    @include screen-xs {
      clear: both;
      min-width: 100%;
    }
    @include screen-range-md {
      min-width: 35%;
      padding: 0.5em;
      margin: 0em;
    }
  }
  &__icon {
    color: $text__dark;
    justify-content: center;
    display: flex;
    margin-right: 0px;
    height: 88px;
    align-self: center;
    &--padded {
      padding: 12px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      flex: 1;
    }
  }
  &__split {
    display: flex;
    justify-content: first baseline;
    height: 100%;
  }
  &__title {
    font-size: 22px;
    color: $link;
    font-weight: 600;
    margin: 0;
  }
  &__description {
    color: $text__dark;
    font-size: 14px;
    font-weight: 400;
  }
}
