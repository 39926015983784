@import 'color/colors';
@import 'mixins';

.TestimonialsList {
  &__wrapper {
    padding: 30px 16px 20px;
    width: 100%;
    @include screen-lg {
      padding: 50px 0 40px;
    }
  }
  &__header {
    color: $text__dark;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    padding: 0 0 8px;
    border-bottom: thin solid $border;
    margin-bottom: 40px;
    @media (min-width: 1280px) {
      margin-bottom: 60px;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    column-gap: 16px;
    @include screen-md {
      grid-template-columns: 1fr 1fr;
    }
    @include screen-lg {
      row-gap: 24px;
      column-gap: 20px;
    }
    width: 100%;
    justify-content: space-between;
  }

  &__testimonial {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @include screen-md {
      max-width: 360px;
    }
    @include screen-lg {
      max-width: 460px;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
    @media (min-width: 1080px) {
      max-width: 540px;
    }
    // &Image {
    //   height: fit-content;
    //   // border-radius: 50%;
    //   width: 100%;
    //   max-width: 160px;
    //   @include screen-md {
    //     max-width: 140px;
    //   }
    //   @include screen-lg {
    //     max-width: 160px;
    //   }
    // }
    &Row {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      height: 60px;
      h4 {
        font-size: 20px;
        color: $text__dark;
        font-weight: bold;
        margin: 0;
      }
    }
    &Logo {
      background-color: transparent;
      position: relative;
      // margin-right: 20px;

      .savvi-tooltip {
        top: 0;
        bottom: unset;
      }
      img {
        max-height: 100%;
        width: 100%;
        object-fit: contain;
        position: relative;
      }

      // @media (max-width: 876px) {
      //   height: 100px;
      //   width: 120px;
      // }

      // @media (min-width: 1700px) {
      //   height: 140px;
      //   width: 160px;
      // }
    }
    &Divider {
      height: 20px;
      width: 1px;
      margin: 0 20px 0 0;
      background-color: $border;
    }
    &Content {
      background-color: transparent;
      z-index: 10;
      // display: flex;
      // flex-direction: column;
      // margin-top: 8px;
      // margin-left: 16px;
      p {
        color: $text__dark-1;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }
  }
}
