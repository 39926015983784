@import 'color/colors';
@import 'mixins';

.FloatingList {
  &__wrapper {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    padding: 12px 0;
    position: absolute;
    background-color: white;
    z-index: 100;
    border-radius: 10px;
    font-size: 18px;
    color: $text__dark-1;
    width: 400px;
    @media (max-width: 1000px) {
      width: 300px;
      padding-left: 30px;
      .FloatingList__title {
        font-size: 14px;
      }
      .FloatingList__imgWrapper {
        width: 34px;
        img {
          max-height: 30px
        }
      }
    }
    @include screen-super-small {
      display: none;
    }
  }

  &__imgWrapper {
    width: 40px;
    height: 34px;
    display: grid;
    place-items: center;
    img {
      max-height: 40px;
      filter: brightness(0);
    }
  }

  &__imgTitle--wrapper {
    width: 270px;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    position: relative;
    padding-right: 0;
  }
  /** The svg */
  &__item--icon {
    width: 1em;
    height: 1em;
  }

  /** The text to the right of the svg */
  &__title {
    color: $text__dark-1;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding-left: 12px;
  }

  /** The UL element */
  &__list {
    list-style: none;
    padding-left: 0px;
    width: fit-content;
    margin: 0 auto;
    padding-left: 30px;
    border-left: thin solid $light-95;
  }
  /** The LI elements */
  &__item {
    list-style: none;
    position: relative;
    padding: 0;
    &::before {
      position: absolute;
      left: -4px;
      top: 50%;
      content: ' ';
      background-color: $light-95;
      width: 12px;
      height: 1px;
    }
  }

  /** Hides top part of left border for first item. */
  &__item--first::after {
    left: -32px;
    top: 0;
    width: 8px;
    height: 49%;
    position: absolute;
    background-color: $white;
    content: ' ';
  }
  /** Hides bottom part of left border for last item. */
  &__item--last::after {
    left: -32px;
    top: 54%;
    width: 8px;
    height: 50%;
    position: absolute;
    background-color: $white;
    content: ' ';
  }
}
