* {
  box-sizing: border-box;
}

html {
  font-family: 'SF Pro Display', -apple-system, 'BlinkMacSystemFont', 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-size: 1em;
  color: #222;
  height: 100%;
  min-height: 100%;
}

body {
  background-color: white;
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  overflow-x: hidden;
}

a {
  color: $blue;
  text-decoration: none;
}

.app {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

ul {
  list-style: none;
}

li {
  color: $light-110;
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: 300;
}

li::before {
  content: '•';
  color: $mint;
}

li::before {
  content: '•';
  color: $mint;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.container {
  @include layout-padding;
  @media (max-width: 568px) {
    padding-right: 0;
  }
}

section {
  max-width: unset;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
