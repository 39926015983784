@import 'color/colors';
@import 'mixins';

$tier__height: 80px;
$tier__columnWidth: 180px;
$tier__columnWidth-lg: 200px;

.Plans {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  &__wrapper {
    margin: 0;
    width: 100%;

    @media (max-width: 1280px) {
      max-width: 960px;
      padding: 0 20px;
      overflow-x: auto;
    }

    @media (max-width: 1400px) {
      max-width: 1080px;
    }

    @media (min-width: 1400px) {
      max-width: 1280px;
    }
  }

  &__introductionText {
    color: #0d2238;
    font-weight: 0;
    letter-spacing: 0;
  }

  &__introductionText--large {
    font-size: 50px;
    line-height: 71px;
    padding-top: 1em;
  }

  &__introductionText--normal {
    width: 634px;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 20px;
    .myBtn--link {
      font-size: inherit;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__tieredContainer {
    display: contents;
  }

  &__relief {
    padding: 0.5em;
  }
  &__relief--md {
    padding: 2.5em;
  }
  &__relief--lg {
    padding: 5.5em;
  }

  &__checkMark {
    max-width: 1em;
  }

  &__upToWrapper {
    position: relative;
    top: -0.2em;
  }

  &__upTo--small {
    font-size: 12px;
  }

  &__tieredColumns {
    display: grid;
    grid-template-columns: 1.25fr repeat(4, 1fr);
    margin-left: 80px;
    max-width: 1040px;

    @media (max-width: 1240px) {
      margin-left: 40px;
    }
    @media (max-width: 1080px) {
      margin-left: 0;
      max-width: inherit;
      grid-template-columns: 210px repeat(4, $tier__columnWidth);
    }
  }
  &__tieredColumnsHeaders {
    display: contents;
    &--stuck {
      .Plans__tier {
        border-bottom: thin solid $border;
      }
    }
  }

  &__dash {
    font-size: 22px;
  }

  &__tierFeature {
    display: flex;
    align-items: center;
    min-width: 222px;
    p {
      color: $text__normal;
      font-size: 18px;
      font-weight: 400;
      margin: 0;
    }
    @media (max-width: 1240px) {
      p {
        font-size: 16px;
      }
    }
  }

  &__tierInclusion {
    align-items: center;
    color: $text__dark-1;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    height: $tier__height;
    justify-content: center;
    text-align: center;
    &--bordered {
      border-right: 1px solid $text__light;
    }
    @media (max-width: 1440px) {
      min-width: $tier__columnWidth-lg;
    }
    @media (max-width: 1080px) {
      min-width: $tier__columnWidth;
    }
  }

  &__tierFeatureExplanation {
    filter: invert(50%);
    height: 12px;
    width: 12px;
    z-index: -101;
  }

  &__tier {
    position: sticky;
    top: 0;
    background-color: $white;
    min-height: 80px;
    padding-bottom: 20px;
    z-index: 5;
    border-bottom: thin solid transparent;
    transition: all 0.5s ease 0.25s;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__tierType {
    color: $text__dark-1;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__tierCost {
    color: $text__dark;
    font-size: 34px;
  }

  &__tierCallUs {
    color: $link;
    font-size: 32px;
    &:hover {
      color: $link--light;
      text-decoration: underline;
    }
  }

  &__tierLength {
    font-size: 0.8em;
    color: $text__dark-1;
  }

  &__getStarted {
    margin-top: 17px;
    width: 210px;
    height: 50px;
    font-size: 20px;
    min-width: 141px;
    align-self: flex-start;
  }

  &__contactUs {
    margin-top: 17px;
    width: 180px;
    height: 50px;
    font-size: 20px;
  }
}
