// New
/// Slightly mix lighten a color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// Nic's design colors
$text__dark: #0d2238;
$text__normal: #2d363c;
$text__dark-1: #60696f;
$text__light: #afbdc5;
$text__light-1: shade(#afbdc5, 20%);

$tooltip: rgba(70, 70, 70, 0.9);
$footer--bg: #f8fafb;
$link: #3ba8ed;
$link--light: tint(#3ba8ed, 20%);
$link--dark: shade(#3ba8ed, 20%);
$new-primary: $link;
$new-primary--light: $link--light;
$new-primary--dark: $link--dark;

// Layout
$dark: #222222;
$dark-85: #444444;
$dark-75: #555555;
$dark-60: #686868;
$dark-50: #818486;
$light-110: #bdc8cd;
$light: #e0e0e0;
$light-95: #e9ecee;
$light-90: #f7f8f9;
$white: #fff;

$border: #cccccc;

$orange: #f16c33;
$orange--dark: #d54e14;
$orange--light: #efad90;

$red: #e04f4f;
$red--light: #e98282;
$red--light-1: #efa4a4;
$red--light-2: #f3c0c0;
$red--light-3: #f8d7d7;
$red--dark: #b13e3e;
$red--dark-1: #682424;

$dark-cerulean: #0c6987;
$dark-cerulean--light: #13a3d4; // iris blue
$dark-cerulean--light-1: #0d7294;
$dark-cerulean--dark: #063747;
$dark-cerulean--dark-1: #0a556e;

$mint: #09ebe7;
$mint-hover: lighten(#81f3ea, 10%);
$mint--light: #81f3ea;
$mint--light-background: #cff5f3ea;
$mint--light-background-1: #cff5f394;
$mint--dark: #00b9aa;

$powder-blue: #bde3e5;
$light-blue: #eaf4f9;
$light-blue-2: #ebf6fd;

$blue: $link;
$blue--light: $link--light;
$blue--dark: $link--dark;

$primary: $dark-cerulean;
$primary--light: $dark-cerulean--light;
$primary--light-hover: lighten($primary--light, 10%);
$primary--light-1: $dark-cerulean--light-1;
$primary--dark: $dark-cerulean--dark;
$primary--dark-1: $dark-cerulean--dark-1;

// latest marketing
@mixin text__subheader {
  font-size: 18px;
  color: $text__normal;
  font-weight: 500;
  line-height: 24px;
}

@mixin text__h2 {
  font-size: 28px;
  font-weight: 700px;
  line-height: 32px;
  color: $text__normal
}

@mixin text__p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: $text__dark-1;
  margin: 0;
}

@mixin text__p2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: $text__dark-1;
  margin: 0;
}

@mixin text__bold {
  font-size: 20px;
  margin: 0;
  line-height: 32px;
  font-weight: 600;
  color: $text__normal;
}
