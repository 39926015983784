@import 'color/colors';
@import 'mixins';

.RoundedList {
  &__item::before {
    /** Remove annoying blue dot */
    display: none;
  }
  &__wrapper {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    padding: 12px 0;
    position: absolute;
    background-color: white;
    z-index: 100;
    border-radius: 10px;
    font-size: 18px;
    color: $text__dark-1;
    width: 400px;
    @include screen-super-small {
      display: none;
    }
  }

  &__imgWrapper {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__imgTitle--wrapper {
    width: 10em;
    display: flex;
    flex-direction: row;
    padding-top: 0.3em;
    position: relative;
    // top: 0.6em;
    padding-right: 0em;
  }
  /** The svg */
  &__item--icon {
    width: 1em;
    height: 1em;
  }

  /** The text to the right of the svg */
  &__title {
    color: $text__dark-1;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  /**
    * Formatting is a little funky on
    * the very first li element. We need
    * to manually adjust it here.
    */
  &__item--first > &__title {
    padding-top: 1em;
    left: 0.4em;
    @include screen-range-md {
      padding-top: 0em;
    }
  }

  /**
    * Some more formatting tweaks to make the
    * text line up correctly in a uniform way.
    */
  //&__item > &__title {
  //  //left: -0.5em;
  //  //top: 0.5em;
  //  //position: relative;
  //}

  /** The UL element */
  &__list {
    list-style: none;
    padding-left: 0px;
    width: fit-content;
    margin: 0 auto;
  }
  /** The LI elements */
  &__item {
    list-style: none;
    padding: 0
  }

  /** The first LI element */
  &__item--first {
    border-left: 1px solid $light-95;
  }
  &__item--first > &__hr--hack::before {
    left: -2px;
    top: -23px;
    // left: -22px;
    // top: -23px;
    position: relative;
    content: " ";
    width: 20px;
    height: 10px;
    z-index: 102;
    border-left: 2px solid white;
    //border-bottom: 4px solid white;
    border-top: 4px solid white;
  }
  &__item--mid {
    border-left: 1px solid $light-95;
  }
  &__item--mid > &__hr--hack::before {
    left: -1px;
    top: -40px;
    position: relative;
    content: " ";
    color:$light-95;
    height: 10px;
    z-index: 101;
    border-left: 1px solid $light-95;
  }
  &__item--last > &__hr--hack::before {
    top: -30px;
    position: relative;
    z-index: 101;
  }
  &__item--last > &__hr--hack::after {
    border-left: 1px solid $light-95;
    content:" ";
    color: $light-95;
    left: -1px;
    position: relative;
    top: -23px;
  }

  /**
    * The "hr" (horizontal rule) hack is what I use
    * to form the horizontal line between the left
    * border and the SVG
    */
  &__hr--hack  {
    margin-top: 16px;
    width: 0.4em;
    height: 10px;
    float: left;
    border-bottom: 1px solid $light-95;
    margin-right: 18px;
  }
  &__hr--hack::before {
    position: relative;
    top: -20px;
    content:" ";
    color: $light-95;
    border-left: 1px solid $light-95;
    width: 20px;
  }
}