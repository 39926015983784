@import 'color/colors';
@import 'mixins';

.SolutionsList {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 16px 20px;
    @include screen-lg {
      padding: 50px 0 40px;
    }
  }
  &__title {
    color: $text__dark;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    padding: 0 0 8px;
    margin-bottom: 30px;
    border-bottom: thin solid $border;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    @include screen-xs {
      display: block;
    }
  }
}
