@import 'color/colors';

.myBtn {
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  padding: 4px 16px;
  align-items: center;
  justify-content: center;
  transition: 0.05s all linear;
  &:focus {
    outline: none;
  }

  &--sm {
    font-size: 0.9em;
    min-width: 100px;
    height: 32px;
  }

  &--md {
    font-size: 20px;
    min-width: 160px;
    height: 48px;
  }

  &--wide {
    font-size: 1.1em;
    min-width: 200px;
    height: 48px;
  }

  &--lg {
    font-size: 1.5em;
    min-width: 200px;
    height: 56px;
  }

  &--primary {
    color: $white;
    background-color: $new-primary;
    border: thin solid darken($new-primary, 5%);
    box-shadow: -2px 6px 8px -2px transparentize($new-primary, 0.6);
    text-align: center;
    &:hover {
      background-color: $new-primary--light;
      border: thin solid $new-primary--light;
      color: $white;
    }
    &:active {
      box-shadow: none;
      transform: none;
    }

    &.isWarning {
      background-color: $orange;
      border: thin solid darken($orange, 5%);
      box-shadow: -2px 6px 8px -2px transparentize($orange, 0.6);
      &:hover {
        background-color: $orange--light;
        border: thin solid $orange--light;
      }
      &:active {
        box-shadow: none;
        outline: none;
      }
    }
  }
  &--secondary {
    background-color: $white;
    border: thin solid $new-primary;
    color: $new-primary;

    &:hover {
      background: $new-primary--light;
      border: thin solid $new-primary--light;
      color: $white;
    }
    &:active {
      outline: none;
    }

    &.isWarning {
      border: 2px solid $orange;
      color: $orange;
      transition: all 0.02s;

      &:hover {
        background: $orange--light;
        border: thin solid $orange--light;
        color: $white;
      }
      &:active {
        border: thin solid $orange--dark;
      }
    }
  }
  &--link {
    color: $link;
    text-decoration: underline;
    align-items: flex-start;
    height: auto;
    min-width: unset;
    padding: 2px 4px;
    &:hover {
      color: $link--light;
    }
    &.isWarning {
      color: $orange;
      &:hover {
        color: $orange--light;
      }
    }
  }
  &--primaryLink {
    background-color: $link;
    border: thin solid darken($link, 5%);
    box-shadow: -2px 6px 8px -2px transparentize($link, 0.6);
    color: $white;

    &:hover {
      background-color: $link--light;
      border: thin solid $link--light;
    }
    &:active {
      box-shadow: none;
      outline: none;
    }

    &.isWarning {
      background-color: $orange;
      border: thin solid darken($orange, 5%);
      box-shadow: -2px 6px 8px -2px transparentize($orange, 0.6);
      &:hover {
        background-color: $orange--light;
        border: thin solid $orange--light;
      }
      &:active {
        box-shadow: none;
        outline: none;
      }
    }
  }
}
